<template>
    <v-container
            id="user-profile"
            fluid
            tag="section"
    >
        <v-row justify="center">
            <v-col
                    cols="12"
                    md="12"
            >
                <base-material-card>
                    <template v-slot:heading>
                        <div class="display-2 font-weight-light">
                            Laporan Pembelian
                        </div>

                        <div class="subtitle-1 font-weight-light">
                            Filter
                        </div>
                    </template>

                    <v-form ref="form">
                        <v-container class="py-0">
                            <v-row>
                                <v-col
                                        cols="12"
                                        md="3"
                                >
                                    <v-menu
                                            v-model="menuTgl"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="290px"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-text-field
                                                    v-model="dateFormatted"
                                                    class="purple-input"
                                                    label="Tanggal Awal"
                                                    readonly
                                                    v-on="on"
                                            >
                                                <v-icon slot="prepend" color="green" size="30">mdi-calendar
                                                </v-icon>
                                            </v-text-field>
                                        </template>
                                        <v-date-picker v-model="date" @input="menuTgl = false"></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col
                                        cols="12"
                                        md="3"
                                >
                                    <v-menu
                                            v-model="menuTgl2"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="290px"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-text-field
                                                    v-model="dateFormatted2"
                                                    class="purple-input"
                                                    label="Tanggal Akhir"
                                                    readonly
                                                    v-on="on"
                                            >
                                                <v-icon slot="prepend" color="green" size="30">mdi-calendar
                                                </v-icon>
                                            </v-text-field>
                                        </template>
                                        <v-date-picker v-model="date2" @input="menuTgl2 = false"></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col
                                        cols="12"
                                        md="3"
                                >
                                    <input type="hidden" v-model="supplier_id"/>
                                    <v-text-field
                                            class="purple-input"
                                            readonly
                                            label="Supplier"
                                            v-model="supplier_name"
                                    >
                                        <v-icon slot="append" color="blue" size="30"
                                                @click="openModalSupplier()">mdi-magnify
                                        </v-icon>
                                        <v-icon slot="append" color="red" size="30"
                                                @click="clearSupplier()">mdi-close
                                        </v-icon>
                                    </v-text-field>
                                </v-col>
                                <v-col
                                        cols="12"
                                        md="2"
                                        class="text-center"
                                        style="padding-top: 2%"
                                >
                                    <v-btn
                                            color="success"
                                            class="mr-0"
                                            @click="generate"
                                    >
                                        Tampilkan
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                </base-material-card>
                <v-card>
                    <v-card-title>Laporan Pembelian</v-card-title>
                    <v-card-text><h4>Periode {{dateFormatted}} {{dateFormatted ? "s/d" : ""}} {{dateFormatted2}}</h4>
                    </v-card-text>
                    <v-card-text>
                        <v-row>
                            <v-col
                                    cols="12"
                                    md="12">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <thead style="background-color: #4caf50;">
                                        <tr>
                                            <th class="text-left" width="10%"
                                                style="font-weight: bold;font-size: 15px;color:white">
                                                Tanggal
                                            </th>
                                            <th class="text-left" width="12%"
                                                style="font-weight: bold;font-size: 15px;color:white">
                                                Supplier
                                            </th>
                                            <th class="text-left" style="font-weight: bold;font-size: 15px;color:white">
                                                Item
                                            </th>
                                            <th class="text-left" style="font-weight: bold;font-size: 15px;color:white">
                                                Harga
                                            </th>
                                            <th class="text-left" style="font-weight: bold;font-size: 15px;color:white">
                                                Qty
                                            </th>
                                            <th class="text-left" style="font-weight: bold;font-size: 15px;color:white">
                                                Total
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <template v-for="(item, index) in dataPembelian">
                                            <tr >
                                                <!--v-for="(item, index) in dataPembelian"-->
                                                <!--:key="index"-->
                                                <!--&gt;-->
                                                <td>
                                                    {{ index == 0 || (index > 0 && item.tgl != dataPembelian[index - 1].tgl) ? item.tgl : ""
                                                    }}
                                                </td>
                                                <td>
                                                    {{ item.supplier_name }}
                                                </td>
                                                <td>{{ item.item }}</td>
                                                <td class="text-left">
                                                    <input type="hidden" v-model.lazy="item.harga" v-money="money"/>
                                                    {{ item.harga }}
                                                </td>
                                                <td class="text-left">
                                                    <input type="hidden" v-model.lazy="item.qty" v-money="justnumber"/>
                                                    {{ item.qty }}
                                                </td>
                                                <td class="text-left">
                                                    <input type="hidden" v-model.lazy="item.total" v-money="money"/>
                                                    {{ item.total }}
                                                </td>
                                            </tr>
                                            <tr v-if="item.subtotalQty_pembelian !== null">
                                                <td colspan="4" class="text-right"
                                                    style="font-weight: bold;padding-right:6%">Subtotal
                                                </td>
                                                <td class="text-left" style="font-weight: bold">
                                                    <input type="hidden" v-model.lazy="item.subtotalQty_pembelian"
                                                           v-money="justnumber"/>
                                                    {{ item.subtotalQty_pembelian }}
                                                </td>
                                                <td class="text-left" style="font-weight: bold">
                                                    <input type="hidden" v-model.lazy="item.subtotal_pembelian"
                                                           v-money="money"/>
                                                    {{ item.subtotal_pembelian }}
                                                </td>
                                            </tr>
                                        </template>
                                        <tr style="background-color: #f4dba8">
                                            <td colspan="4" class="text-right"
                                                style="font-weight: bold;padding-right:6%">Total
                                            </td>
                                            <td class="text-left" style="font-weight: bold">
                                                <input type="hidden" v-model.lazy="totalQty_pembelian"
                                                       v-money="justnumber"/>
                                                {{ totalQty_pembelian }}
                                            </td>
                                            <td class="text-left" style="font-weight: bold">
                                                <input type="hidden" v-model.lazy="total_pembelian" v-money="money"/>
                                                {{ total_pembelian }}
                                            </td>
                                        </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>

                    </v-card-text>
                </v-card>
                <v-dialog v-model="modalSupplier" persistent min-width="350" max-width="70%">
                    <v-container>
                        <v-row>
                            <v-col>
                                <base-material-card>
                                    <template v-slot:heading>
                                        <div class="display-2 font-weight-light">

                                            <v-row>
                                                <v-col cols="12" md="11">
                                                    Daftar Supplier
                                                </v-col>
                                                <v-col cols="12" md="1" class="text-right">
                                                    <v-icon @click="modalSupplier = false">mdi-close-circle</v-icon>
                                                </v-col>
                                            </v-row>

                                        </div>

                                        <div class="subtitle-1 font-weight-light">
                                        </div>
                                    </template>
                                    <v-row>
                                        <v-col cols="12"
                                               lg="6">
                                            <v-text-field
                                                    v-model="searchSupplier"
                                                    label="Search"
                                                    single-line

                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <div class="py-1"/>
                                    <v-data-table
                                            :headers="headersSupplier"
                                            :items="itemsSupplier"
                                            :search="searchSupplier"
                                    >

                                        <template v-slot:item.action="{ item }">
                                            <v-icon class="mx-1" color="green darken-2" @click="pilihSupplier(item)">
                                                mdi-check-bold
                                            </v-icon>
                                            <!--<span class="mx-1" color="green darken-2"-->
                                            <!--@click="pilihBarang(item)">Pilih-->
                                            <!--</span>-->
                                        </template>
                                    </v-data-table>

                                    <v-row justify="end">
                                        <v-col cols="12"
                                               lg="2">
                                            <v-btn color="blue darken-1" text @click="modalSupplier = false">Tutup
                                            </v-btn>
                                            <v-spacer></v-spacer>
                                        </v-col>
                                    </v-row>


                                </base-material-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-dialog>
                <v-dialog v-model="dialog" persistent max-width="290">
                    <v-card>
                        <v-card-text class="text-center">
                            Loading
                            <v-spacer></v-spacer>
                            <v-progress-linear
                                    indeterminate
                                    rounded
                                    color="yellow darken-2"
                            ></v-progress-linear>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-snackbar
                        v-model="snackbar"
                        :color="color"
                        :top="true"
                >
                    <span>{{message}}</span>
                    <v-btn
                            dark
                            icon
                            @click="snackbar = false">

                        <v-icon>
                            $vuetify.icons.cancel
                        </v-icon>
                    </v-btn>
                </v-snackbar>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import {ApiUrl} from '../../../../config/constant'
    import axios from 'axios'
    import {
        mapState,
    } from 'vuex'
    import {VMoney} from 'v-money'

    export default {
        name: 'LaporanPembelian',
        data() {
            return {
                money: {
                    decimal: ',',
                    thousands: '.',
                    prefix: 'Rp ',
                    suffix: '',
                    precision: 0,
                    masked: false /* doesn't work with directive */
                },
                justnumber: {
                    decimal: ',',
                    thousands: '.',
                    prefix: '',
                    suffix: '',
                    precision: 0,
                    masked: false /* doesn't work with directive */
                },
                snackbar: false,
                dialog: false,
                message: '',
                color: 'success',
                date: new Date().toISOString().substr(0, 10),
                dateFormatted: '',
                menuTgl: false,
                date2: new Date().toISOString().substr(0, 10),
                dateFormatted2: '',
                menuTgl2: false,
                subtotalQty_pembelian: 0,
                subtotal_pembelian: 0,
                total_pembelian: 0,
                totalQty_pembelian: 0,
                supplier_name: '',
                supplier_id: 0,
                status_bayar: 'Semua',
                status_order: 'Semua',
                dataPembelian: [
//                    {
//                        id: 1,
//                        tgl: '11-01-2021',
//                        item: 'Capuccino',
//                        qty: 2,
//                        harga: 10000,
//                        total: 20000,
//                    },
                ],
                modalSupplier: false,
                searchSupplier: '',
                headersSupplier: [
                    {
                        sortable: false,
                        text: 'No',
                        value: 'no',
                        align: 'center',
                        width: 50,
                    },
                    {
                        sortable: true,
                        text: 'Nama',
                        filterable: true,
                        value: 'name',
                        width: 200,
                    },
                    {
                        sortable: true,
                        text: 'Alamat',
                        filterable: true,
                        value: 'address',
                        align: 'start',
                        width: 200,
                    },
                    {
                        sortable: true,
                        text: 'Telp',
                        filterable: true,
                        value: 'telp',
                        align: 'start',
                        width: 200,
                    },
                    {
                        sortable: false,
                        text: 'Aksi',
                        value: 'action',
                        align: 'center',
                        width: 70
                    },
                ],
                itemsSupplier: [],

            }
        },
        computed: {
            ...mapState(['currentUser']),
            computedDateFormatted() {
                return this.formatDate(this.date)
            },
            computedDateFormatted2() {
                return this.formatDate2(this.date2)
            },
        },
        watch: {
            date: function (val) {
                this.formatDate(val)
            },
            date2: function (val) {
                this.formatDate2(val)
            }
        },
        mounted: function () {
            this.dataSupplier()
            this.computedDateFormatted
            this.computedDateFormatted2
        },
        methods: {
            formatDate: function (date) {
                if (!date) return null
                const [year, month, day] = date.split('-')
                if (year.length > 3)
                    this.dateFormatted = `${day}-${month}-${year}`
                else
                    this.dateFormatted = this.date
            },
            formatDate2: function (date) {
                if (!date) return null
                const [year, month, day] = date.split('-')
                if (year.length > 3)
                    this.dateFormatted2 = `${day}-${month}-${year}`
                else
                    this.dateFormatted2 = this.date2
            },
            openModalSupplier: function () {
                this.modalSupplier = true
            },
            clearSupplier: function () {
                this.supplier_id = 0
                this.supplier_name = ''
            },
            dataSupplier: function (e) {
                let token = this.currentUser.token;
                let header = {
                    'Authorization': 'Bearer ' + token,
                    'Content-type': 'application/json',
                }
                axios
                ({method: 'get', url: ApiUrl + 'suppliers', headers: header})
                    .then(response => {
                        console.log(response)
//                        this.dialog = false
                        this.isLoadData = true
                        if (response.data.success) {
                            this.itemsSupplier = response.data.data
                        }
                    })
                    .catch(error => (
                        console.log(error)
                    ))
            },
            pilihSupplier: function (item) {
                console.log('pilih supplier')
                console.log(item)
                this.supplier_id = item.id
                this.supplier_name = item.name
                this.modalSupplier = false
            },
            generate: function () {
                if (this.date != '' && this.date2 != '') {
                    let token = this.currentUser.token;
                    let header = {
                        'Authorization': 'Bearer ' + token,
                        'Content-type': 'application/json'
                    }
                    let data = {
                        tglawal: this.date,
                        tglakhir: this.date2,
                    }
                    if (this.supplier_id != 0 && this.supplier_id != "") {
                        data.supplier_id = this.supplier_id
                    }
                    
                    this.dialog = true
                    axios
                    ({method: 'post', url: ApiUrl + 'laporan/pembelian', data: data, headers: header})
                        .then(response => {
                            console.log(response)
                            this.dialog = false
                            if (response.data.success) {
                                this.snackbar = true
                                this.color = 'success'
                                this.message = 'Berhasil generate laporan                    '
                                this.dataPembelian = response.data.dataPembelian
                                this.total_pembelian = response.data.totalHarga
                                this.totalQty_pembelian = response.data.totalQty
//                                this.totalQty_pembelian = response.data.dataTotPembelian[0].tot_qty
//                                this.total_pembelian = response.data.dataTotPembelian[0].tot_price
//                                this.$refs.form.resetValidation()
                            } else {
                                this.snackbar = true
                                this.color = 'error'
                                this.message = 'Gagal generate laporan                    '
                            }
                        })
                        .catch(error => {
                            this.dialog = false
                            this.snackbar = true
                            this.color = 'error'
                            this.message = 'Gagal generate laporan \n ' + error.message
                            console.log(error)
                        })
                } else {
                    this.snackbar = true
                    this.color = 'error'
                    this.message = 'Tanggal awal dan akhir harus diisi'
                }
            },
        }
    }
</script>
